import { Box } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export interface HeroBackgroundProps extends PropsWithChildren<{}> {
    mobileHeight?: string
    desktopHeight?: string
}

const mobileSvg = (mobileHeight: string = '375') => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        viewBox={`0 0 ${mobileHeight} 1430`}
        fill='none'
    >
        <path
            d={`M0 0H${mobileHeight}V1377.13L0 1430L0 0Z`}
            fill='url(#paint0_linear_4456_39006)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_4456_39006'
                gradientTransform='rotate(7)'
            >
                <stop offset='0%' stopColor='#2C2D8B' />
                <stop offset='70%' stopColor='#5C61AC' />
                <stop offset='100%' stopColor='#3276C7' />
            </linearGradient>
        </defs>
    </svg>
)

const desktopSvg = (desktopHeight: string = '1440') => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='100%'
        height='100%'
        viewBox={`0 0 ${desktopHeight} 900`}
        fill='none'
    >
        <path
            d={`M0 0H${desktopHeight}V695.5L0 899.652V0Z`}
            fill='url(#paint0_linear_4456_39005)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_4456_39005'
                gradientTransform='rotate(7)'
            >
                <stop offset='0%' stopColor='#2C2D8B' />
                <stop offset='50.41%' stopColor='#5C61AC' />
                <stop offset='100%' stopColor='#3276C7' />
            </linearGradient>
        </defs>
    </svg>
)

export const HeroBackground: React.FC<HeroBackgroundProps> = ({ children, desktopHeight, mobileHeight }) => {
    return (
        <>
            <Box position='relative' mb='26px'>
                <Box
                    position='absolute'
                    w='full'
                    zIndex='-1'
                    display={['none', 'none', 'none', 'block']}
                >
                    {desktopSvg(desktopHeight)}
                </Box>
                <Box
                    position='absolute'
                    w='full'
                    zIndex='-1'
                    display={['block', 'block', 'block', 'none']}
                >
                    {mobileSvg(mobileHeight)}
                </Box>
                {children}
            </Box>
        </>
    )
}
