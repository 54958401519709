import { Box, SimpleGrid, Image } from '@chakra-ui/react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { HeroBackground } from 'common/components/molecules/HeroBackground';
import React from 'react';
import { Wrapper } from 'common/components/molecules/Wrapper';
import { TopIcon } from './components/TopIcon';
import { Pillar } from './components/Pillar';
import { ShardCardCarrousel } from './components/ShardCardCarrousel';
import { useSlideShow } from 'common/hooks/useSlideShow/useSlideShow';
import * as S from './HeroWithPillarLinks.styles';
import arrow from './arrow.svg';
import { Stepper } from '@irishlifedigitalhub/ds.molecules.stepper';
import { motion } from 'framer-motion';
import PlayPauseButton from 'common/components/molecules/PlayPauseButton/PlayPauseButton';

export interface IHeroWithPillarLinksProps {
  codename?: string;
  icon?: string;
  lastModified?: string;
  pillars: IPillar[];
  shard_cards?: IShardCard[];
  transition_speed?: number;
  subtitle?: string;
  title: string;
  type?: string;
}

export interface IShardCard {
  codename: string;
  lastModified: string;
  type: string;
  templatesection___shardcard__title?: string;
  templatesection___shardcard__text?: string;
  templatesection___shardcard__image?: string;
  templatesection___shardcard__cta?: CTAProps[];
}

export interface CTAProps {
  codename?: string;
  lastModified?: string;
  type?: string;
  call_to_action__variant?: string;
  call_to_action__label?: string;
  call_to_action__label_mobile?: string;
  call_to_action__jumbotron_label?: string;
  call_to_action__url?: string;
  call_to_action__jumbotron_url?: string;
  call_to_action__title?: string;
  call_to_action__title_mobile?: string;
  call_to_action__analytics_id?: string;
  call_to_action__jumbotron_analytics_id?: string;
}
export interface IPillar {
  codename: string;
  lastModified: string;
  type: string;
  icon?: string;
  title: string;
  title_mobile?: string;
  cta_url: string;
  cta_label: string;
  cta_analytics_id?: string;
  top_border_color?: string;
  background_color?: string;
}

const HeroWithPillarLinks: React.FC<any> = (
  props: IHeroWithPillarLinksProps
) => {
  const enableSlideshow = () => {
    if (
      !props.shard_cards ||
      (props.shard_cards && props.shard_cards.length === 0)
    ) {
      return false;
    }
    if (props.shard_cards && props.shard_cards.length === 1) {
      return false;
    }
    return true;
  };

  const isEnabledSlideShow: boolean = enableSlideshow();

  const {
    index,
    goToSlide,
    prevSlide,
    nextSlide,
    autoSlideshow,
    toggleAutoSlideshow,
  } = useSlideShow({
    length: props.shard_cards ? props.shard_cards.length : 0,
    transition_speed: props.transition_speed ? props.transition_speed : 6,
    isEnabledSlideShow: isEnabledSlideShow,
  });

  const StepperJSX = () => {
    return props.shard_cards &&
      props.shard_cards.length > 0 &&
      isEnabledSlideShow ? (
      <S.StepperBox display="flex" gap="20px">
        <Image src={arrow} onClick={prevSlide} />
        <S.StepperWrapper>
          <Stepper
            defaultStep={index + 1}
            steps={props.shard_cards.length}
            leftIndicator={true}
            onChange={(activeStep: number) => goToSlide(activeStep - 1)}
          />
        </S.StepperWrapper>
        <Image src={arrow} transform="rotate(180deg)" onClick={nextSlide} />
        <PlayPauseButton
          active={autoSlideshow}
          callback={() => toggleAutoSlideshow()}
        />
      </S.StepperBox>
    ) : (
      <></>
    );
  };

  return (
    <div>
      <HeroBackground desktopHeight="940" mobileHeight="275">
        <Wrapper>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            width="100%"
            gap="24px"
            paddingTop="48px"
            paddingBottom="96px"
            paddingLeft={['16px', '16px', '16px', 'none']}
            paddingRight={['16px', '16px', '16px', 'none']}
          >
            <Box
              display="flex"
              flexDirection="column"
              width={['100%', '100%', '100%', '455px']}
              gap="24px"
              justifyContent="center"
            >
              <Box display="flex" flexDirection="column" gap="12px">
                <TopIcon props={props} />
                <Text
                  variant={['title-lg', 'title-lg', 'title-lg', 'title-2xl']}
                  fontWeight="medium"
                  color={['white', 'white', 'white', 'white']}
                >
                  {props.title}
                </Text>
              </Box>
              {props.subtitle && (
                <Text
                  variant={['title-xs', 'title-xs', 'title-xs', 'title-md']}
                  color={['white', 'white', 'white', 'white']}
                >
                  {props.subtitle}
                </Text>
              )}
            </Box>
            <Box display="flex" flex="1">
              {props.pillars && props.pillars.length > 0 && (
                <SimpleGrid columns={2} spacing="8px" w="full" px="0">
                  {props.pillars.map((pillar) => (
                    <Pillar key={pillar.codename} pillar={pillar} />
                  ))}
                </SimpleGrid>
              )}
            </Box>
          </Box>
          {props.shard_cards && props.shard_cards.length > 0 && (
            <Box overflow={'hidden'}>
              <motion.div
                id="shard-cards-carrousel"
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                animate={{ x: -index * 100 + '%' }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                {props.shard_cards.map((card, i) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    mx="auto"
                    minWidth="100%"
                    height={['auto', 'auto', 'auto', '326px']}
                    key={`shard-card-${i}`}
                    id={`shard-card-${i}`}
                    className={`shard-card ${i === index ? 'active' : ''}`}
                    transition="transform 0.5s ease"
                    paddingLeft={['16px', '16px', ' 16px', 'none']}
                    paddingRight={['16px', '16px', ' 16px', 'none']}
                  >
                    <ShardCardCarrousel
                      shardCard={card}
                      stepper={StepperJSX()}
                    />
                  </Box>
                ))}
              </motion.div>
            </Box>
          )}
        </Wrapper>
      </HeroBackground>
    </div>
  );
};
export { HeroWithPillarLinks };
